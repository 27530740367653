import {inject, Injectable, Injector} from '@angular/core';
import {
  DefaultTranspiler,
  getFunctionArgs, HashMap,
  isString,
  TranslocoService,
  TranslocoTranspiler,
  TranspileParams,
} from '@jsverse/transloco';
import {Translation} from '@jsverse/transloco/lib/types';


interface TranslocoTranspilerFunctionWithParams {
  transpile(params: HashMap, ...args: string[]): string;
}

@Injectable()
export class FunctionalTranspilerWithParams extends DefaultTranspiler implements TranslocoTranspiler {
  protected injector = inject(Injector);

  public override transpile({value, ...rest}: TranspileParams): unknown {
    let transpiled = value;
    if (isString(value)) {
      transpiled = value.replace(
        /\[\[\s*(\w+)\((.*?)\)\s*]]/g,
        (match: string, functionName: string, args: string) => {
          try {
            const func: TranslocoTranspilerFunctionWithParams = this.injector.get(functionName);

            return func.transpile(rest.params, ...getFunctionArgs(args).map(arg => {
              try {
                const transpiledArg = this.transpile({value: arg, ...rest});
                if (isString(transpiledArg)) {
                  return transpiledArg;
                }
              } catch (e) {
                console.error(e);
              }
              return arg;
            }));
          } catch (e: unknown) {
            console.error(e);
            let message = `There is an error in: '${value}'.
                          Check that the you used the right syntax in your translation and that the implementation of ${functionName} is correct.`;
            if ((e as Error).message.includes('NullInjectorError')) {
              message = `You are using the '${functionName}' function in your translation but no provider was found!`;
            }
            throw new Error(message);
          }
        },
      );
    }

    return super.transpile({value: transpiled, ...rest});
  }
}

@Injectable({
  providedIn: 'root',
})
export class NestedArgumentFallback implements TranslocoTranspilerFunctionWithParams {
  constructor(
    private translocoService: TranslocoService,
  ) {}

  public transpile(
    params: HashMap,
    nestedParameter: string,
    translationIfNestedParameterIsFound: string,
    translationIfNestedParameterIsNotFound: string,
  ): string {
    const translations: Translation = this.translocoService.getTranslation().get(this.translocoService.getActiveLang());

    if (Object.keys(translations).find((k) => k === nestedParameter)) {
      return `{{ ${translationIfNestedParameterIsFound} }}`;
    } else {
      return `{{ ${translationIfNestedParameterIsNotFound} }}`;
    }
  }
}
